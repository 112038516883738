<template>
  <div class="question">
      <div class="row">
          <div class="col-md-3">{{ activityProgressTracker.title }}</div>
          <div class="col-md-3">{{ activityProgressTracker.id }}</div>
          <div class="col-md-3">{{ activityProgressTracker.start_date }}</div>
          <div v-if="isActive" div class="col-md-3">{{$t('activityprogresstrackerCard.activa')}}</div>
          <div v-else div class="col-md-3">{{$t('activityprogresstrackerCard.inactiva')}}</div>
          <div class="col-md-3">{{ activityProgressTracker.veces_completadas }}</div>
          <div class="col-md-3">{{activityProgressTracker.multiplicacion_completadas_respondidas}}</div>
      </div>
  </div>
</template>

<script>
let today = new Date()
today = `${`${today.getDate()}`.padStart(2, '0')}/${`${today.getMonth() + 1}`.padStart(2, '0')}/${today.getFullYear()}`
export default {
  name: 'ActivityProgressTrackerCard',
  props: ['activityProgressTracker'],
  computed: {
      tracker () {
          const tracker = this.$utils.cloneObj(this.activityProgressTracker)
          return tracker
      },
      isActive(){
      let activityEvent = true
      if (this.activityProgressTracker.end_date < today) {
        console.log(this.activityProgressTracker.end_date,today )
         activityEvent = false
      }
      else if(this.activityProgressTracker.end_date == null && this.activityProgressTracker.deleted){
        activityEvent = false
      }else if(this.activityProgressTracker.end_date > today){
        activityEvent = true
      }
      return activityEvent
    },
  }    
}
</script>

<style>
.row {
display: flex;
width: 100%;
}

.header {
display: flex;
font-weight: bold;
margin-bottom: 15px;
}

.col-md-3 {
flex: 1;
margin-right: 10px; 
text-align: center;
}
</style>
