<template>
  <base-view-layout>
    <template #breadcrumb>
      <li class="breadcrumb__nav-item">{{$t('activityprogresstracker.breadcrumb')}}</li>
    </template>

    <template #title>{{$t('activityprogresstracker.title')}}</template>
    <template #subtitle>{{$t('activityprogresstracker.subtitle')}}</template>

    <section class="main-section">
      <div class="search">
        <div class="search__group">
          <div class="ico-search"><img src="@/assets/images/icons/ico-search--black.svg"></div>
          <form class="search_form" @submit.prevent="performSearch()">
            <form-input v-if="$i18n.locale == 'es'" v-model.trim="searchTerm" type="search" :autofocus="true" placeholder="Buscar" @input="performSearch()"></form-input>
            <form-input v-else-if="$i18n.locale == 'en'" v-model.trim="searchTerm" type="search" :autofocus="true" placeholder="Search" @input="performSearch()"></form-input>
          </form>
        </div>
        <div class="select">
          <div class="form__group">
            <select class="form__input" v-model.trim="searchOption">
              <option value="" disabled selected>{{$t('activityprogresstracker.searchOptions')}}</option>
              <option value="title">{{$t('activityprogresstracker.titleActivity')}}</option>
              <option value="id">{{$t('activityprogresstracker.idActivity')}}</option>
              <option value="start_date">{{$t('activityprogresstracker.date')}}</option>
              <option value="deleted">{{$t('activityprogresstracker.isActive')}}</option>
            </select>
          </div>
        </div>
      </div>

      <div class="box box--sm box--no-shadow">
        <div class="question">
          <div class="header">
            <div class="col-md-3">{{$t('activityprogresstracker.titleActivity')}}</div>
            <div class="col-md-3">{{$t('activityprogresstracker.idActivity')}}</div>
            <div class="col-md-3">{{$t('activityprogresstracker.date')}}</div>
            <div class="col-md-3">{{$t('activityprogresstracker.isActive')}}</div>
            <div class="col-md-3">{{$t('activityprogresstracker.number')}}</div>
            <div class="col-md-3">{{$t('activityprogresstracker.respuestas')}}</div>
          </div>
        </div>
        <div class="question-list question-list--sm" v-for="activityProgressTracker in activityProgressTracker" :key="activityProgressTracker.id">
          <activity-progress-tracker-card :activityProgressTracker="activityProgressTracker" />
        </div>
        <div class="header_custom">
        <div class="col-md-1">{{$t('activityprogresstracker.Total_responses')}} {{ suma }}</div>
        </div>
      </div>
    </section>
  </base-view-layout>
</template>

<script>
import { inject, reactive} from 'vue'
import BaseViewLayout from '@/components/BaseViewLayout'
import ActivityProgressTrackerCard from '@/components/ActivityProgressTrackerCard'
import FormInput from '@/components/FormInput'

export default {
  name: 'ActivityProgressTracker',
  components: { BaseViewLayout, ActivityProgressTrackerCard, FormInput },
  setup() {
    const axios = inject('$axios')
    const output = {
      activityProgressTracker: reactive([]),
      axios,
      searchTerm: '',
      searchOption: '',
      suma: 0
    }
    axios
      .get('activity-progress-tracker')
      .then((res) =>{
                res.data.monitoring.forEach((activityProgressTracker) =>
          output.activityProgressTracker.push(activityProgressTracker)
        )
        
        const arreglo = res.data.monitoring.map((item)=>parseInt(item.multiplicacion_completadas_respondidas))
        let suma_parcial = 0
          arreglo.forEach(function(elemento) {
          suma_parcial += elemento;
        });
        output.suma = suma_parcial     
      }

      )
    return output
  },
  methods: {
    async performSearch() {
      const filter = this.searchTerm
      const attributeToSearch  = this.searchOption 

      this.activityProgressTracker.splice(0)
      this.activityProgressTracker.push(...
          (await this.$axios.get('activity-progress-tracker')).
          data.monitoring)
      if(filter!= '' && attributeToSearch  != '') {
        const search = this.activityProgressTracker.filter(object => {
          const regex = new RegExp(`\\b${filter}\\b`, 'i');
          if (regex.test(object[attributeToSearch].toString())) {
            return true;
          }
          return false;     
        });
        this.activityProgressTracker.splice(0);
        this.activityProgressTracker.push(...search);
      }
      else if(filter  != '') {
        const noatributeSelection = 'title'
        const search = this.activityProgressTracker.filter(object => {
          const regex = new RegExp(`\\b${filter}\\b`, 'i');
          if (regex.test(object[noatributeSelection].toString())) {
            return true;
          }
          return false;
        });
        this.activityProgressTracker.splice(0);
        this.activityProgressTracker.push(...search);
      }
    },
  }
}
</script>

<style>
.search {
  display: flex;
}

.search__group {
  width: 80%;
  box-sizing: border-box;
}

.select {
  width: 20%;
  margin-top: 5px;
  box-sizing: border-box;
}

.header_custom{
  display: flex;
    font-weight: bold;
    margin-bottom: 15px;
    flex-direction: row-reverse;
}
</style>
